import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader, Container,
    Input,
    Row,
    Spinner,
    Table
} from "reactstrap";
import confirm from "reactstrap-confirm";
import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { cloneObj } from "../../../shared/utils";
import { getIdeas, updateIdea } from "../../../store/slices/idea/ideaActions";
import { setIsLoading } from "../../../store/slices/shared/sharedSlice";
import Modal from "../components/Modal/Modal";
import { useTranslation } from "react-i18next";

const kpiArray = ['Productivity', 'Quality', 'Cost', 'Delivery', 'Safety', 'Morale'];
const periodArray = ['Daily', 'Weekly', 'Monthly', 'Yearly', 'Quarterly'];

function EditOPLtable() {
    const { t } = useTranslation();
    const { data, stateIdeaText } = useLocation().state;
    const [rows, setRows] = useState(data || [{ id: 1, kpi: 'Productivity', period: 'Daily', before: 0, after: 0, saving: 0, note: '' }]);
    const [idCount, setIdCount] = useState(2);
    const [changed, setChanged] = useState(false);
    const ideas = useSelector((state) => state.idea.ideas);
    const dispatch = useDispatch()
    const id = useParams().id;
    const history = useHistory();
    const [ideaText, setIdeaText] = useState(stateIdeaText || '')
    const isLoading = useSelector((state) => state.shared.isLoading);

    const handleAddRow = () => {
        setRows([...rows, { id: idCount, kpi: 'Productivity', period: 'Daily', before: 0, after: 0, saving: 0, note: '' }]);
        setIdCount(idCount + 1);
    };
    const handleDeleteRow = () => {
        const newRows = [...rows]
        newRows.pop()
        setRows(newRows);
        setIdCount(idCount - 1);
    };

    const handleInputChange = (id, columnName, value) => {
        const updatedRows = rows.map((row) => {
            if (row.id === id) {
                return { ...row, [columnName]: value };
            }
            return row;
        });
        setRows(updatedRows);
    };
    const handleSubmit = async () => {
        try {
            if (changed) {
                let result = await confirm({
                    title: <>Warning</>,
                    message: t("Are you sure you want to submit this?"),
                    confirmText: "Confirm",
                    confirmColor: "primary",
                    cancelColor: "link text-danger",
                });
                let foundIdea = cloneObj(ideas.find(idea => idea._id === id))
                foundIdea.status.implemented.oplDescription = rows
                foundIdea.status.implemented.ideaText = ideaText
                foundIdea.id = id
                if (result) {
                    dispatch(setIsLoading(true))
                    dispatch(updateIdea({
                        idea: {
                            data: foundIdea,
                            key: 'none',
                            id: id
                        },
                        dispatch, path: '/admin/idea', history, setIsLoading
                    }));
                }
            }
        } catch (e) {
            return e
        }
    }
    useEffect(() => {
        dispatch(getIdeas())
    }, [])
    return (
        <>
            <SimpleHeader name="Edit Knowledge Table" parentName="Idea management" />
            <Modal visible={isLoading} >
                <Spinner
                    color="light"
                    style={{
                        height: "5rem",
                        width: "5rem",
                    }}
                >
                    Loading...
                </Spinner>
            </Modal>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <div className="card-wrapper">
                            <Card>
                                <CardHeader>
                                    <h3 className="mb-0">Edit</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row xs="11" md="9">
                                        <div className="w-100 mb-4 text-center">
                                            {!data?.length && <>
                                                <Button color="primary" type="button" onClick={handleAddRow} >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="ni ni-fat-add" />
                                                    </span>
                                                    <span className="btn-inner--text">Add Row</span>
                                                </Button>
                                                <Button disabled={rows.length <= 1} color="danger" type="button" onClick={handleDeleteRow} >
                                                    <span className="btn-inner--icon mr-1">
                                                        <i className="ni ni-fat-delete" />
                                                    </span>
                                                    <span className="btn-inner--text">Delete Row</span>
                                                </Button>
                                            </>
                                            }
                                        </div>
                                        <Table className="align-items-center" responsive bordered >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" >
                                                        #
                                                    </th>
                                                    <th scope="col" >
                                                        KPI(automated)
                                                    </th>
                                                    <th scope="col" >
                                                        Period
                                                    </th>
                                                    <th scope="col" >
                                                        Before
                                                    </th>
                                                    <th scope="col" >
                                                        After
                                                    </th>
                                                    <th scope="col" >
                                                        Saving
                                                    </th>
                                                    <th scope="col" >
                                                        Notes
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="list">
                                                {rows.map((row) => (
                                                    <tr key={row.id}>
                                                        <td>{row.id}</td>
                                                        <td>
                                                            <Input
                                                                disabled={data?.length}
                                                                className="form-control-sm"
                                                                type={`${data?.length ? 'text' : 'select'}`}
                                                                value={row?.kpi}
                                                                onChange={(e) => {
                                                                    handleInputChange(row.id, 'kpi', e.target.value)
                                                                    setChanged(true)
                                                                }
                                                                }
                                                            >
                                                                {kpiArray.map(kpiElement => <option key={kpiElement}>{kpiElement}</option>)}

                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                disabled={data?.length}
                                                                className="form-control-sm"
                                                                type={`${data?.length ? 'text' : 'select'}`}
                                                                value={row?.period}
                                                                onChange={(e) => {
                                                                    handleInputChange(row.id, 'period', e.target.value)
                                                                    setChanged(true)
                                                                }
                                                                }
                                                            >
                                                                {periodArray.map(periodElement => <option key={periodElement}>{periodElement}</option>)}
                                                            </Input>
                                                        </td>
                                                        <td>
                                                            <Input
                                                                disabled={data?.length}
                                                                className="form-control-sm"
                                                                type="number"
                                                                value={row?.before}
                                                                onChange={(e) => {
                                                                    handleInputChange(row.id, 'before', +e.target.value)
                                                                    setChanged(true)
                                                                }
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                disabled={data?.length}
                                                                className="form-control-sm"
                                                                type="number"
                                                                value={row?.after}
                                                                onChange={(e) => {
                                                                    handleInputChange(row.id, 'after', +e.target.value)
                                                                    setChanged(true)
                                                                }
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                disabled={data?.length}
                                                                className="form-control-sm"
                                                                type="number"
                                                                value={row?.saving}
                                                                onChange={(e) => {
                                                                    handleInputChange(row.id, 'saving', +e.target.value)
                                                                    setChanged(true)
                                                                }
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            <Input
                                                                disabled={data?.length}
                                                                className="form-control-sm"
                                                                type="text"
                                                                value={row?.note}
                                                                onChange={(e) => {
                                                                    handleInputChange(row.id, 'note', e.target.value)
                                                                    setChanged(true)
                                                                }
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row className="mt-5" xs="11" md="9">
                                        <h3>Knowledge Report</h3>
                                        <Input
                                            type="textarea"
                                            name="ideaText"
                                            value={ideaText}
                                            disabled={stateIdeaText}
                                            onChange={(e) => {
                                                setIdeaText(e.target.value)
                                                if (e.target.value) {
                                                    setChanged(true)
                                                } else {
                                                    setChanged(false)
                                                }
                                            }}
                                        />
                                    </Row>
                                    <div className="text-center">
                                        <Button color="primary" className="my-4" onClick={handleSubmit} disabled={!changed || ((data?.length) && stateIdeaText)}>
                                            Submit
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default EditOPLtable