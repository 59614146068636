import axios from "axios";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import RedAsterisk from "../../components/RedAsterisk/RedAsterisk";
import { API_URLS } from "../../constants/API_URLS";
import NotificationAlert from "react-notification-alert";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select2 from "react-select2-wrapper";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Row,
} from "reactstrap";
import confirm from "reactstrap-confirm";
import stickyNotesImg from "../../assets/img/brand/stickyNotes.jpg";
import CustomCheckbox from "../../components/CustomCheckbox/CustomCheckbox";
import { upperCaseFirstLetter } from "../../shared/utils";
import { getAICMinibusinesses } from "../../store/slices/AICMinibusiness/AICMinibusinessActions";
import { getareaSections } from "../../store/slices/areaSection/areaSectionActions";
import { getBusinessUnits } from "../../store/slices/factoryMinibusinessUnit/factoryMinibusinessUnitActions";
import { ideaSubmit } from "../../store/slices/idea/ideaActions";
import {
  effectOnPQCDSMArray,
  ideaPresenterType,
  kaizenTypes,
} from "../../utils/Data";
import {
  animatedComponents,
  filterValuesInIdeasFilter,
  notify,
} from "../../utils/helpers";
import { getEmployeeCodeSheets } from "../../store/slices/EmployeeCodeSheet/EmployeeCodeSheetActions";
import Select from "react-select";

let timeoutId;

function IdeaForm() {
  const factoryBusinessUnits = useSelector(
    (state) => state?.factoryBusinessUnit?.businessUnits
  );
  const areaSections = useSelector((state) => state?.areaSection?.areaSections);
  const aicMinibusinesses = useSelector(
    (state) => state?.AICMinibusiness?.AICMinibusinesses
  );
  const usersCode = useSelector(
    (state) => state?.employeeCodeSheets?.employeeCodeSheets
  );
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  let watchedData = watch();

  const ideaText = register("ideaText", {
    required: "This is required",
    minLength: {
      value: 20,
      message: "Idea must be at least 20 characters",
    },
  });
  const ideaDesc = register("ideaDesc", {
    required: "This is required",
    minLength: {
      value: 20,
      message: "Idea must be at least 20 characters",
    },
  });
  const employeeName = register("employeeName", {
    required: "This is required",
    minLength: {
      value: 4,
      message: "Employee name must be at least 4 characters",
    },
  });
  const employeeCode = register("employeeCode", {
    required: "This is required",
    minLength: {
      value: 4,
      message: "Employee code must be at least 4 characters",
    },
    onChange: (e) => {
      if (watchedData.ideaPresenterType === "Employee") {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(async () => {
          const response =
            e.target.value.value &&
            (await axios.get(
              API_URLS.EmployeeCodeSheet.getOne + e.target.value.value
            ));
          if (response?.data?.name) {
            setValue("employeeName", response.data.name);
            // setValue("employeePhone", response.data?.mobile || "");
          }
        }, 1000);
      }
    },
  });
  const estimatedAnualSavings = register("estimatedAnualSavings", {
    valueAsNumber: true,
  });
  const notificationAlertRef = useRef(null);

  function msgAlert(msg) {
    notify("success", msg, notificationAlertRef, "Submitted successfully");
  }

  const onSubmit = async (data) => {
    let result = await confirm({
      title: <>Warning</>,
      message: t("Are you sure you want to submit this?"),
      confirmText: "Confirm",
      confirmColor: "primary",
      cancelColor: "link text-danger",
    });
    const employeeCode =
      watchedData.ideaPresenterType === "Employee"
        ? data.employeeCode.label
        : data.employeeCode;
    const idea = {
      imported: false,
      status: {
        evaluated: {
          value: false,
          canBeReplicated: false,
          questions: {
            isItCheap: false,
            isItEasyToImplement: false,
            canWeImplementItOurselves: false,
            canItBeImplementedWithin3Weeks: false,
            isItOneOfOurTop3FavSuggestions: false,
            willItSaveAsignificantAmout: false,
            isItUrgent: false,
            totalTicks: 0,
            priority: "Low",
            dueDate: "",
            responsiblePerson: [],
          },
        },
        approved: {
          value: false,
          questions: {
            "Will not negatively affect quality/operator/saftey/machine?": false,
            "Will have impact?": false,
            "Did stakeholders approve?": false,
          },
          canBeReplicated: false,
        },
        implemented: {
          value: false,
          savings: 0,
          before: "",
          after: "",
          singlePointLesson: "",
          equipmentName: "",
          oplDescription: {},
        },
        rewarded: { value: false, savings: 0, eventName: "", eventDate: "" },
        replicated: { value: false, type: "Not Replicated" },
      },
      ...data,
      employeeCode: employeeCode,
    };
    idea.date = Date.now();
    delete idea.filesRelated;
    delete idea.operatorImg;
    delete idea.status.operatorImg;
    if (result)
      dispatch(
        ideaSubmit({
          data: idea,
          file: data.filesRelated,
          operatorImg: data.operatorImg ? data.operatorImg : "",
          alertFun: msgAlert,
        })
      );
  };

  const initForm = () => {
    dispatch(getBusinessUnits());
    dispatch(getareaSections());
    dispatch(getAICMinibusinesses());
    dispatch(getEmployeeCodeSheets());
  };
  useEffect(() => {
    initForm();
  }, []);

  useEffect(() => {
    if (watchedData.ideaPresenterType !== "Employee" && timeoutId) {
      clearTimeout(timeoutId);
      setValue("employeeName", "");
      setValue("employeeCode", "");
    }
  }, [watchedData.ideaPresenterType]);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="header bg-gradient-info py-7 py-lg-8 pt-lg-9"></div>
      <div className="bg-gradient-info py-lg-8 pt-lg-9">
        <Container className="mt--6 pb-5">
          <Row className="justify-content-center">
            <Col xs="12" md="9">
              <Card className="bg-secondary border-0 mb-0">
                <img
                  alt="sticky notes"
                  src={stickyNotesImg}
                  style={{
                    height: "200px",
                    objectFit: "cover",
                    borderRadius: "0.375rem 0.375rem 0 0",
                  }}
                />
                <Col
                  xs="11"
                  style={{
                    background: "#f7fafc",
                    borderRadius: "15px",
                    margin: "auto",
                    marginTop: "-4rem",
                    boxShadow: "5px 5px 5px #00000040",
                    padding: "1rem",
                  }}
                >
                  <h1>{t("defineProblemForm")}</h1>
                  <p>{t("presentYourIdea")}</p>
                </Col>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form
                    role="form"
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "30px",
                    }}
                  >
                    {/* ideaPresenterType */}
                    <Row xs="11" md="9">
                      <Controller
                        rules={{ required: "This is required" }}
                        name="ideaPresenterType"
                        defaultValue={"Employee"}
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <h3 className="form-control-label">
                                {t("form.ideaPresenterType")} <RedAsterisk />
                              </h3>
                              <Select2
                                {...field}
                                options={{
                                  placeholder: t("form.Choose"),
                                }}
                                data-minimum-results-for-search="Infinity"
                                data={ideaPresenterType?.map((el) => {
                                  return {
                                    id: el.id,
                                    value: el.id,
                                    text: t(`form.${el.text}`),
                                  };
                                })}
                              />
                            </>
                          );
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        property="factoryBusinessUnit"
                      />
                    </Row>
                    {/* factoryBusinessUnit */}
                    <Row xs="11" md="9">
                      <Controller
                        rules={{ required: "This is required" }}
                        name="factoryBusinessUnit"
                        control={control}
                        render={({ field }) => {
                          return (
                            <>
                              <h3 className="form-control-label">
                                {t("form.factoryBusinessUnit")} <RedAsterisk />
                              </h3>
                              <Select2
                                {...field}
                                options={{
                                  placeholder: t("form.Choose"),
                                }}
                                data-minimum-results-for-search="Infinity"
                                data={factoryBusinessUnits?.map((el) => {
                                  return {
                                    id: el._id,
                                    value: el._id,
                                    text: upperCaseFirstLetter(
                                      el.name[i18n.language]
                                    ),
                                  };
                                })}
                              />
                            </>
                          );
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        property="factoryBusinessUnit"
                      />
                    </Row>
                    {/* areaSections */}
                    {watchedData.factoryBusinessUnit && (
                      <Row xs="11" md="9">
                        <Controller
                          rules={{ required: "This is required" }}
                          name="areaSection"
                          control={control}
                          render={({ field }) => {
                            return (
                              <>
                                <h3 className="form-control-label">
                                  {t("form.areaSections")} <RedAsterisk />
                                </h3>
                                <Select2
                                  {...field}
                                  options={{
                                    placeholder: t("form.Choose"),
                                  }}
                                  data-minimum-results-for-search="Infinity"
                                  data={filterValuesInIdeasFilter(
                                    areaSections,
                                    "factoryBusinessUnit",
                                    watchedData.factoryBusinessUnit,
                                    true
                                  )?.map((el) => {
                                    return {
                                      id: el._id,
                                      value: el._id,
                                      text: upperCaseFirstLetter(
                                        el.name[i18n.language]
                                      ),
                                    };
                                  })}
                                />
                              </>
                            );
                          }}
                        />
                        <ErrorMessage errors={errors} property="areaSection" />
                      </Row>
                    )}
                    {/* AICMinibusiness */}
                    {watchedData.areaSection && (
                      <Row xs="11" md="9">
                        <Controller
                          rules={{ required: "This is required" }}
                          name="AICMinibusiness"
                          control={control}
                          render={({ field }) => {
                            return (
                              <>
                                <h3 className="form-control-label">
                                  {t("form.AICMinibusiness")} <RedAsterisk />
                                </h3>
                                <Select2
                                  {...field}
                                  options={{
                                    placeholder: t("form.Choose"),
                                  }}
                                  data-minimum-results-for-search="Infinity"
                                  data={filterValuesInIdeasFilter(
                                    aicMinibusinesses,
                                    "areaSection",
                                    watchedData.areaSection,
                                    true
                                  )?.map((el) => {
                                    return {
                                      id: el._id,
                                      value: el._id,
                                      text: upperCaseFirstLetter(
                                        el.name[i18n.language]
                                      ),
                                    };
                                  })}
                                />
                              </>
                            );
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          property="AICMinibusiness"
                        />
                      </Row>
                    )}
                    {/* ideaText */}
                    <Row xs="11" md="9">
                      <h3 className="form-control-label">
                        {t("form.idea")} <RedAsterisk />
                      </h3>
                      <Input
                        type="textarea"
                        name="ideaText"
                        onChange={ideaText.onChange}
                        onBlur={ideaText.onBlur}
                        innerRef={ideaText.ref}
                        style={{ maxHeight: "300px" }}
                      />
                      <ErrorMessage errors={errors} property="ideaText" />
                    </Row>
                    {/* ideaDesc */}
                    <Row xs="11" md="9">
                      <h3 className="form-control-label">
                        {t("form.ideaDesc")} <RedAsterisk />
                      </h3>
                      <Input
                        type="textarea"
                        name="ideaDesc"
                        onChange={ideaDesc.onChange}
                        onBlur={ideaDesc.onBlur}
                        innerRef={ideaDesc.ref}
                        style={{ maxHeight: "300px" }}
                      />
                      <ErrorMessage errors={errors} property="ideaDesc" />
                    </Row>
                    {/* employeeCode */}
                    <>
                      {watchedData.ideaPresenterType === "Employee" ? (
                        <Row xs="11" md="9" className="d-flex flex-column">
                          <Controller
                            rules={{ required: "This is required" }}
                            control={control}
                            placeholder={t("form.enterEmployeeID")}
                            name="employeeCode"
                            onChange={employeeCode.onChange}
                            onBlur={employeeCode.onBlur}
                            innerRef={employeeCode.ref}
                            render={({ field }) => (
                              <>
                                <h3 className="form-control-label">
                                  {t("form.employeeCode")} <RedAsterisk />
                                </h3>
                                <Select
                                  {...field}
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  maxMenuHeight={150}
                                  options={usersCode?.map((el) => {
                                    return {
                                      value: el._id,
                                      label: el.code,
                                    };
                                  })}
                                />
                              </>
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            property="employeeCode"
                          />
                        </Row>
                      ) : (
                        <Row xs="11" md="9">
                          <h3 className="form-control-label">
                            {t("form.employeeCode")} <RedAsterisk />
                          </h3>
                          <Input
                            type="text"
                            placeholder={t("form.enterEmployeeID")}
                            name="employeeCode"
                            onChange={employeeCode.onChange}
                            onBlur={employeeCode.onBlur}
                            innerRef={employeeCode.ref}
                          />
                          <ErrorMessage
                            errors={errors}
                            property="employeeCode"
                          />
                        </Row>
                      )}
                    </>
                    {/* employeeName */}
                    <Row xs="11" md="9">
                      <h3 className="form-control-label">
                        {t("form.employeeName")} <RedAsterisk />
                      </h3>
                      <Input
                        type="text"
                        name="employeeName"
                        onChange={employeeName.onChange}
                        onBlur={employeeName.onBlur}
                        innerRef={employeeName.ref}
                        disabled={watchedData.ideaPresenterType === "Employee"}
                      />
                      <ErrorMessage errors={errors} property="employeeName" />
                    </Row>
                    {/* files related */}
                    <Row xs="11" md="9">
                      <h3>{t("form.uploadYourFiles")}</h3>
                      <div className="custom-file">
                        <Controller
                          control={control}
                          name="filesRelated"
                          render={({
                            field: { value, onChange, ...field },
                          }) => {
                            return (
                              <input
                                {...field}
                                className="custom-file-input"
                                onChange={(event) => {
                                  onChange(event.target.files[0]);
                                }}
                                type="file"
                                id="filesRelated"
                              />
                            );
                          }}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileLang"
                        >
                          {t("form.selectFile")}
                        </label>
                      </div>
                      <ErrorMessage errors={errors} property="filesRelated" />
                    </Row>
                    {/* effect on pqcdsm */}
                    <Row xs="11" md="9">
                      <h3 style={{ width: "100%" }}>
                        {t("form.effectOnPQCDSM")} <RedAsterisk />
                      </h3>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                          width: "100%",
                        }}
                      >
                        {effectOnPQCDSMArray.map((effect) => (
                          <CustomCheckbox
                            key={effect.id}
                            value={effect.text.en}
                            id={effect.id}
                            label={effect.text[i18n.language]}
                            register={register("effectOnPQCDSM", {
                              required: "This is required.",
                            })}
                          />
                        ))}
                      </div>
                      <ErrorMessage errors={errors} property="effectOnPQCDSM" />
                    </Row>
                    {/* kaizenType */}
                    <Row xs="11" md="9">
                      <Controller
                        name="kaizenType"
                        defaultValue={kaizenTypes[0].text.en}
                        control={control}
                        render={({ field }) => (
                          <>
                            <h3 className="form-control-label">
                              {t("form.kaizenType")}
                            </h3>
                            <Select2
                              {...field}
                              options={{
                                placeholder: t("form.chooseTheKaizenType"),
                              }}
                              data-minimum-results-for-search="Infinity"
                              data={kaizenTypes?.map((el) => {
                                return {
                                  id: el.text.en,
                                  value: el.text.en,
                                  text: el.text[i18n.language],
                                };
                              })}
                            />
                          </>
                        )}
                      />
                      <ErrorMessage errors={errors} property="kaizenType" />
                    </Row>
                    {/* estimatedAnualSavings */}
                    <Row xs="11" md="9">
                      <h3 className="form-control-label">
                        {t("form.estimatedAnualSavings")}
                      </h3>
                      <Input
                        type="number"
                        min={0}
                        defaultValue={0}
                        name="estimatedAnualSavings"
                        onChange={estimatedAnualSavings.onChange}
                        onBlur={estimatedAnualSavings.onBlur}
                        innerRef={estimatedAnualSavings.ref}
                      />
                      <ErrorMessage
                        errors={errors}
                        property="estimatedAnualSavings"
                      />
                    </Row>
                    {/* upload your photo */}
                    <Row xs="11" md="9">
                      <h3>{t("form.operatorImg")}</h3>
                      <div className="custom-file">
                        <Controller
                          control={control}
                          name="operatorImg"
                          render={({
                            field: { value, onChange, ...field },
                          }) => {
                            return (
                              <input
                                {...field}
                                className="custom-file-input"
                                onChange={(event) => {
                                  onChange(event.target.files[0]);
                                }}
                                type="file"
                                id="operatorImg"
                              />
                            );
                          }}
                        />
                        <label className="custom-file-label">
                          {t("form.selectFile")}
                        </label>
                      </div>
                      <ErrorMessage errors={errors} property="operatorImg" />
                    </Row>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="submit">
                        {t("submit")}
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default IdeaForm;
