import moment from "moment";

export const statusFilter = [
  { value: "evaluated", label: "Evaluated" },
  { value: "approved", label: "Approved" },
  { value: "implemented", label: "Implemented" },
  { value: "rewarded", label: "Rewarded" },
];

export const replicationSelectValues = [
  { value: "Fully Replicated", label: "Fully Replicated" },
  { value: "Partially Replicated", label: "Partially Replicated" },
  { value: "Not Replicated", label: "Not Replicated" },
  { value: "Can't Be Replicated", label: "Can't Be Replicated" },
];

export const estimatedSavingsType = [
  { value: "Hard Saving", label: "Hard Saving" },
  { value: "Cost Avoidance", label: "Cost Avoidance" },
];
export const statusApproval = [
  { value: true, label: "Accepted" },
  { value: false, label: "Rejected" },
  { value: "hold", label: "Hold" },
];
export const rejectedReasons = [
  { value: "High cost", label: "High cost" },
  { value: "Not accepted technically", label: "Not accepted technically" },
  { value: "Already submitted before", label: "Already submitted before" },
  { value: "Against company policy", label: "Against company policy" },
  { value: "Low impact/ No saving", label: "Low impact/ No saving" },
  { value: "Other", label: "Other" },
];

export function ideaTableColumnsShowLess(lang, t) {
  return [
    {
      dataField: "ideaText",
      text: t("idea.ideaText"),
      sort: true,
      align: "left",
    },
    {
      dataField: "effectOnPQCDSM",
      text: t("idea.effectOnPQCDSM"),
      formatter: (cell) => cell.map((el) => t(`idea.${el}`)) + "",
      sort: true,
      align: "left",
    },
    {
      dataField: "employeeName",
      text: t("idea.employeeName"),
      sort: true,
      align: "left",
    },
  ];
}
export function ideaTableColumns(lang, t) {
  return [
    {
      dataField: "_id",
      text: "ID",
      align: "left",
    },
    {
      dataField: `factoryBusinessUnit.name.${lang}`,
      text: t("idea.factoryBusinessUnit"),
      sort: true,
      align: "left",
    },
    {
      dataField: `areaSection.name.${lang}`,
      text: t("idea.areaSection"),
      sort: true,
      align: "left",
    },
    {
      dataField: `AICMinibusiness.name.${lang}`,
      text: t("idea.miniBusiness"),
      sort: true,
      align: "left",
    },
    {
      dataField: "effectOnPQCDSM",
      text: t("idea.effectOnPQCDSM"),
      formatter: (cell) => cell.map((el) => t(`idea.${el}`)) + "",
      sort: true,
      align: "left",
    },
    {
      dataField: "ideaText",
      text: t("idea.ideaText"),
      sort: true,
      align: "left",
    },
    {
      dataField: "employeeName",
      text: t("idea.employeeName"),
      sort: true,
      align: "left",
    },
    {
      dataField: "employeeCode",
      text: t("idea.employeeCode"),
      sort: true,
      align: "left",
    },
    {
      dataField: "kaizenType",
      text: t("idea.kaizenType"),
      sort: true,
      align: "left",
      formatter: (cell) => t(`idea.${cell}`),
    },
    {
      dataField: "estimatedAnualSavings",
      text: t("idea.estimatedAnnualSavingsEmployee"),
      sort: true,
      align: "left",
    },
    {
      dataField: "createdAt",
      text: t("idea.date"),
      sort: true,
      align: "left",
      formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
    },
    {
      dataField: "status.replicated.type",
      text: t("idea.replicationStatus"),
      sort: true,
      align: "left",
      formatter: (cell) => t(`idea.${cell}`),
    },
    {
      dataField: "status.evaluated.questions.totalTicks",
      text: t("idea.totalTicks"),
    },
    {
      dataField: "status.evaluated.questions.priority",
      text: t("idea.priority"),
      formatter: (cell) => t(`approved.${cell}`),
    },
    {
      dataField: "status.evaluated.evaluatedBy",
      text: "Evaluated by",
    },
    {
      dataField: "status.approved.estimatedSavings.amount",
      text: t("idea.estimatedAnnualSavingsManager"),
    },
    {
      dataField: "status.approved.estimatedSavings.type",
      text: t("idea.estimatedAnnualSavingsTypeManager"),
      formatter: (cell) => cell && t(`approved.${cell}`),
    },
    {
      dataField: "status.approved.approvedBy",
      text: t("idea.approvedBy"),
    },
    {
      dataField: "status.implemented.cost",
      text: t("idea.cost"),
    },
    {
      dataField: "status.implemented.savings",
      text: t("idea.savings"),
    },
    {
      dataField: "status.implemented.roi",
      text: t("idea.roi"),
    },
    {
      dataField: "status.rewarded.savings",
      text: t("idea.reward"),
    },
    {
      dataField: "status.implemented.reasonOfDenial",
      text: t("implemented.reasonOfDenial"),
    },
  ];
}
export const effectOnPQCDSMArray = [
  {
    id: "prod",
    text: {
      en: "Productivity",
      ar: "الانتاجية",
    },
  },
  {
    id: "qua",
    text: {
      en: "Quality",
      ar: "الجودة",
    },
  },
  {
    id: "cost",
    text: {
      en: "Cost",
      ar: "التكلفة",
    },
  },
  {
    id: "deliv",
    text: {
      en: "Delivery",
      ar: "التوصيل",
    },
  },
  {
    id: "safe",
    text: {
      en: "Safety",
      ar: "السلامة",
    },
  },
  {
    id: "mora",
    text: {
      en: "Morale",
      ar: "الروح المعنوية",
    },
  },
];

export const kaizenTypes = [
  {
    text: {
      en: "Kaizen",
      ar: "كايزن",
    },
  },
  {
    text: {
      en: "Improvements",
      ar: "تحسينات",
    },
  },
];

export const quarters = [
  {
    text: {
      en: "Q1",
      ar: "الربع الأول",
    },
  },
  {
    text: {
      en: "Q2",
      ar: "الربع الثاني",
    },
  },
  {
    text: {
      en: "Q3",
      ar: "الربع الثالث",
    },
  },
  {
    text: {
      en: "Q4",
      ar: "الربع الرابع",
    },
  },
];

export function masterDataTableColumns(t) {
  return [
    {
      dataField: "_id",
      text: "ID",
      align: "left",
    },
    {
      dataField: "name.en",
      text: t("masterData.English Name"),
      sort: true,
      align: "left",
    },
    {
      dataField: "name.ar",
      text: t("masterData.Arabic Name"),
      sort: true,
      align: "left",
    },
  ];
}
export function usersDataTableColumns(t, lang) {
  return [
    {
      dataField: "_id",
      text: "System ID",
    },
    {
      dataField: "username",
      text: t("users.Username"),
      sort: true,
      align: "left",
    },
    {
      dataField: "userType",
      text: t("users.User Type"),
      sort: true,
    },
    {
      dataField: "userCode",
      text: "User ID",
    },
    {
      dataField: "mobile",
      text: t("users.Mobile"),
      sort: true,
      width: 1,
    },
    {
      dataField: "email",
      text: t("users.Email Address"),
      sort: true,
      align: "left",
    },
    {
      dataField: `factoryBusinessUnit.name.${lang}`,
      text: t("users.factoryBusinessUnit"),
    },
  ];
}

export function kaizenTemplateTableColumns(lang, t) {
  return [
    {
      dataField: "_id",
      text: "ID",
      align: "left",
    },
    {
      dataField: `factoryBusinessUnit.name.${lang}`,
      text: t("idea.factoryBusinessUnit"),
      sort: true,
      align: "left",
    },
    {
      dataField: `areaSection.name.${lang}`,
      text: t("idea.areaSection"),
      sort: true,
      align: "left",
    },
    {
      dataField: `AICMinibusiness.name.${lang}`,
      text: t("idea.miniBusiness"),
      sort: true,
      align: "left",
    },
    {
      dataField: "ideaText",
      text: t("idea.ideaText"),
      sort: true,
      align: "left",
    },
    {
      dataField: "createdAt",
      text: t("idea.date"),
      sort: true,
      align: "left",
      formatter: (cell) => moment(cell).format("DD/MM/YYYY"),
    },
    {
      dataField: "employeeName",
      text: t("idea.employeeName"),
      sort: true,
      align: "left",
    },
    {
      dataField: "employeeCode",
      text: t("idea.employeeCode"),
      sort: true,
      align: "left",
    },
    {
      dataField: "effectOnPQCDSM",
      text: t("idea.effectOnPQCDSM"),
      formatter: (cell) => cell.map((el) => t(`idea.${el}`)) + "",
      align: "left",
    },
    {
      dataField: "kaizenType",
      text: t("idea.kaizenType"),
      sort: true,
      align: "left",
      formatter: (cell) => t(`idea.${cell}`),
    },
    {
      dataField: "status.replicated.type",
      text: t("idea.replicationStatus"),
      sort: true,
      align: "left",
      formatter: (cell) => t(`idea.${cell}`),
    },
    {
      dataField: "status.implemented.savings",
      text: t("idea.savings"),
    },
    {
      dataField: "status.implemented.roi",
      text: t("idea.roi"),
    },
  ];
}
export const ideaPresenterType = [
  {
    id: "Visitor",
    text: "Visitor",
  },
  {
    id: "Employee",
    text: "Employee",
  },
];

export const ideaStatusType = [
  {
    id: "Employee ID",
  },
  {
    id: "Idea ID",
  },
];
export const userTypes = {
  admin: "ADMIN",
  consultant: "CONSULTANT",
  keyOwner: "KEY_OWNER",
  projectTeam: "PROJECT_TEAM",
  keyLeader: "KEY_LEADER",
  hr: "HR",
  employee: "EMPLOYEE",
};
