import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { Button, Card, CardHeader, Col, FormGroup, Input, Row } from "reactstrap";
import { notify } from "../../../../utils/helpers";
import { replicationSelectValues } from "../../../../utils/Data";

const Replicated = ({ data, setChangedData, id }) => {
    const notificationAlertRef = useRef(null);
    const { t } = useTranslation();
    const [dataChanged, setDataChanged] = useState(false);
    const [replicatedType, setReplicatedType] = useState(data);


    const saveChangesHandler = () => {
        notify(
            "success",
            'Your changes have been saved.',
            notificationAlertRef,
            'Saved Successfully'
        );
        const replicatedChanges = {
            value: true,
            date: new Date(),
            type: replicatedType
        }
        setChangedData({
            data: {
                replicated:
                    replicatedChanges,
            },
            key: 'replicated',
            id: id
        });
    }
    return (
        <Card className="w-100 p-3">
            <div className="rna-wrapper">
                <NotificationAlert ref={notificationAlertRef} />
            </div>
            <CardHeader>
                <p className="h3 text-capitalize">
                    {t('editIdea.replicated')}
                </p>
            </CardHeader>
            <Row className="w-100">
                <Col className="m-5" md='4' >
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="replicatedSelect"
                        >
                            Type
                        </label>
                        <Input
                            id="replicatedSelect"
                            type="select"
                            disabled={data === "Fully Replicated"}
                            value={replicatedType}
                            onChange={(e) => {
                                setReplicatedType(e.target.value)
                                setDataChanged(true)
                            }}
                        >
                            {replicationSelectValues.map(el => <option key={el.value} value={el.value}>{t(`editIdea.${el.label}`)}</option>)}
                        </Input>
                    </FormGroup>
                </Col>
            </Row>
            {data !== 'Fully Replicated' && <div className="text-center">
                <Button className="my-4" color="primary" type="button" onClick={saveChangesHandler} disabled={!dataChanged || data === 'Fully Replicated'} >
                    {t('evaluated.saveChanges')}
                </Button>
            </div>}
        </Card>
    );
};
export default Replicated;