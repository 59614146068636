import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardHeader, Container, Row } from "reactstrap";
import confirm from "reactstrap-confirm";
import { deleteAreaSection, getareaSections } from "../../../store/slices/areaSection/areaSectionActions";
import { masterDataTableColumns } from "../../../utils/Data";
import { downloadAsFile } from "../../../utils/helpers";
import { API_URLS } from "../../../constants/API_URLS";
const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
});

const { SearchBar } = Search;
const AreaSection = () => {
    const { i18n, t } = useTranslation();
    const areaSections = useSelector((state) => state?.areaSection?.areaSections) || [];
    const user = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    const deleteOne = async (id) => {
        if (user.userType !== "ADMIN" || user.userType !== "CONSULTANT") {
            let result = await confirm({
                title: <>Warning</>,
                message: t("Are you sure you want to delete this?"),
                confirmText: "Confirm",
                confirmColor: "primary",
                cancelColor: "link text-danger",
            });
            if (result) {
                dispatch(deleteAreaSection(id))
            };
        } else {
            return
        };

    };
    useEffect(() => {
        dispatch(getareaSections());
    }, []);

    return (
        <>
            <SimpleHeader name="MBU" parentName="Management" />
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card>
                            <CardHeader>
                                <h3 className="mb-0">{t('masterData.areaSection')}</h3>
                            </CardHeader>
                            <ToolkitProvider
                                data={areaSections}
                                keyField="_id"
                                columns={[
                                    ...masterDataTableColumns(t),
                                    {
                                        dataField: "factoryBusinessUnit",
                                        text: t('masterData.factoryBusinessUnit'),
                                        formatter: (cell) => {
                                            return cell.map(el => el.name?.[i18n.language]) + " ";
                                        },
                                        sort: true,
                                        align: "left",
                                    },
                                    {
                                        dataField: "_id",
                                        text: t('masterData.Edit'),
                                        formatter: (cell, row, rowIndex, formatExtraData) =>
                                            (user?.userType === "ADMIN" || user?.userType === "CONSULTANT") ? (
                                                <Link
                                                    to={{
                                                        pathname: "/admin/editAreaSection/" + cell,
                                                        state: { data: row },
                                                    }}
                                                    key={cell}
                                                    className="text-primary"
                                                >
                                                    {t('masterData.Edit')}
                                                </Link>
                                            ) : (
                                                () => null
                                            ),
                                    },
                                    {
                                        dataField: "_id",
                                        text: t('masterData.Delete'),
                                        formatter: (cell, row) => (
                                            <>
                                                {user?.userType === "ADMIN" ||
                                                    user?.userType === "CONSULTANT" ||
                                                    user?.userType === "MBUL" ||
                                                    user?.userType === "KEY_OWNER" ? (
                                                    <>
                                                        <span
                                                            style={{ cursor: 'pointer' }}
                                                            className="table-action table-action-delete"
                                                            onClick={() => deleteOne(cell)}
                                                        >
                                                            <i className="fas fa-trash" />
                                                        </span>
                                                    </>
                                                ) : null}
                                            </>
                                        ),
                                    },
                                ]}
                                search
                            >
                                {(props) => (
                                    <div className="py-4 table-responsive">
                                        <div
                                            id="datatable-basic_filter"
                                            className="dataTables_filter px-4 pb-1"
                                        >
                                            <SearchBar
                                                className="form-control-sm"
                                                placeholder=""
                                                {...props.searchProps}
                                            />
                                        </div>
                                        <Button
                                            color="primary"
                                            type="button"
                                            style={{ width: "180px", marginBottom: "10px" }}
                                            onClick={() => {
                                                downloadAsFile(API_URLS.AreaSection.backup, 'MBUs')
                                            }}
                                        >
                                            {t('idea.downloadIdeasBackup')}
                                        </Button>
                                        <BootstrapTable
                                            {...props.baseProps}
                                            hover={true}
                                            tabIndexCell={true}
                                            bootstrap4={true}
                                            pagination={pagination}
                                            bordered={false}
                                            keyField="index"
                                            data={areaSections?.map((row, index) => ({
                                                ...row,
                                                index,
                                            }))}
                                        />
                                    </div>
                                )}
                            </ToolkitProvider>
                            <Link
                                to={{
                                    pathname: "/admin/addAreaSection",
                                }}
                                className="text-primary"
                                style={{
                                    margin: '20px 0',
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                            >
                                <Button color="primary" type="button" style={{ width: '150px' }}>{t('masterData.Add')}</Button>
                            </Link>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default AreaSection;
