import { history } from "_helpers";
import jwt_decode from "jwt-decode";
import { useEffect, useRef } from "react";
import NotificationAlert from "react-notification-alert";
import "react-notification-alert/dist/animate.css";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthRoutes from "./components/PrivateRoute/AuthRoutes";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import { useAppDispatch } from "./store/store";
import { checkJwtExpiration, handleRefreshAndTabsOpen, resetFilterFromLocalStorage } from "./utils/helpers";
import LandingPage from "./views/pages/LandingPage/LandingPage";

export const App = (props) => {
  const token = localStorage.getItem("opexDashbaordToken");
  const dispatch = useAppDispatch();
  const notificationAlertRef = useRef(null);

  useEffect(() => {
    const isActiveSession = handleRefreshAndTabsOpen(token);
    !isActiveSession && resetFilterFromLocalStorage();
  }, []);

  useEffect(() => {
    if (!token) return
    const decoded = jwt_decode(token);
    const timeRemaining = decoded.exp * 1000 - new Date().getTime();
    const jwtTimeOut = setTimeout(() => {
      checkJwtExpiration(token, decoded, dispatch, history, notificationAlertRef)
    }, timeRemaining);
    return () => clearTimeout(jwtTimeOut)
  }, []);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Switch history={history}>
        <Route path='/LandingPage' exact component={LandingPage} />
        <PrivateRoute props={props} path="/admin" component={AdminLayout} />
        <AuthRoutes path="/auth" component={AuthLayout} />
        <Redirect from="/" to="/LandingPage" />
      </Switch>
    </>
  );
};
